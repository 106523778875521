<template>
  <div>
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Business', route: 'Home' },
        { name: 'Manage Business', route: 'Home' },
      ]"
    />
    <div class="placeholder">
      <div class="row">
        <div class="col-6">
          <div class="error" v-for="error in errors" :key="error">
            {{ error }}
          </div>
          <div class="success" v-for="message in messages" :key="message">
            {{ message }}
          </div>
          <form class="form" v-on:submit.prevent="UpdateBusiness">
            <label for="businessinfo.name">Business / Practice Name</label>
            <input
              type="text"
              class="input"
              v-model="businessinfo.name"
              readonly
            />
            <label for="businessinfo.address">Address</label>
            <textarea
              class="input left"
              v-model="businessinfo.address"
              rows="4"
              required
            ></textarea>
            <label for="businessinfo.postcode">Postcode</label>
            <input
              type="text"
              class="input"
              v-model="businessinfo.postcode"
              required
            />
            <label for="businessinfo.contact_email">Contact Email</label>
            <input
              type="email"
              class="input"
              v-model="businessinfo.contact_email"
              required
            />
            <label for="businessinfo.contact_telephone"
              >Contact Telephone</label
            >
            <input
              type="tel"
              class="input"
              v-model="businessinfo.contact_telephone"
              required
            />
            <label for="businessinfo.logo">Logo / Badge</label>
            <input
              type="file"
              class="input"
              accept="image/*"
              @change="logoUpload"
            />
            <br />
            <br />
            <input
              v-if="businessinfo.role > 2"
              type="submit"
              class="btn btn-green"
              value="Save Changes"
            />
          </form>
        </div>
        <div class="col-6">
          <div v-if="organisation == true">
            <div class="info">
              This business is part of the
              {{ organisationinfo.name }} organisation.
            </div>
          </div>
          <br />
          <div class="avail-test-placeholder">
            <div class="tests">{{ businessinfo.credits }}</div>
            <div class="title">Available tests</div>
          </div>
          <br />
          <table class="table">
            <thead>
              <tr>
                <td>Name</td>
                <td>Email</td>
                <td colspan="2">Role</td>
              </tr>
            </thead>
            <tbody>
              <tr v-if="businessinfo.role > 2">
                <td colspan="1">
                  <label for="adduser.email">Email Address</label>
                  <input
                    id="adduser.email"
                    type="email"
                    class="input"
                    v-model="adduser.email"
                    placeholder="example@example.com"
                  />
                </td>
                <td>
                  <label for="adduser.role">Role</label>
                  <select
                    class="input"
                    v-model="adduser.role"
                    id="adduser.role"
                  >
                    <option value="1">Member</option>
                    <option value="2">Lead Member</option>
                    <option value="3">Manager</option>
                    <option value="5">Veterinarian</option>
                    <option value="6">Veterinary Nurse</option>
                    <option value="7">Administrative Staff</option>
                    <option value="8">Practice Manager</option>
                    <option value="9">Store Manager</option>
                    <option value="10">Agricultural Supplier</option>
                    <option value="11">Sales Manager</option>
                  </select>
                </td>
                <td colspan="2">
                  <button v-on:click="inviteUser" class="btn btn-green">
                    Invite User <i class="icon icon-pen"></i>
                  </button>
                </td>
              </tr>
              <tr v-for="(user, index) in users" :key="user">
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td>{{ roleTypes(user.role) }}</td>
                <td v-if="businessinfo.role > 2">
                  <div
                    class="btn btn-orange"
                    v-on:click="RemoveUserFromBusiness(user.id, index)"
                  >
                    <span class="icon icon-delete"></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Navigation from "../components/Navigation.vue";
export default {
  name: "Business_Manage",
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
      businessinfo: "",
      organisation: false,
      organisationinfo: {},
      users: [],
      messages: [],
      errors: [],
      adduser: {
        email: "",
        role: 1,
      },
    };
  },
  components: {
    Navigation,
  },
  methods: {
    GetBusinessInfo() {
      let id = this.$route.params.id;
      this.store
        .dispatch("Business/GetBusinessInfo", { id: id })
        .then((data) => {
          this.businessinfo = data.data;
          this.businessinfo.logo = false;
          this.businessinfo.change_logo = false;
          if (data.organisation) {
            this.organisation = true;
            this.organisationinfo = data.organisation;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetBusinessUsers() {
      let id = this.$route.params.id;
      this.store
        .dispatch("Business/GetBusinessUsers", { id: id })
        .then((data) => {
          this.users = data.data;
          console.log(this.users);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    inviteUser() {
      let id = this.$route.params.id;
      this.store
        .dispatch("Business/InviteBusinessUser", {
          id: id,
          email: this.adduser.email,
          role: this.adduser.role,
        })
        .then((data) => {
          console.log(data);
          this.messages = data.messages;
        })
        .catch((err) => {
          console.log(err);
          this.errors = err.messages;
        });
    },
    roleTypes(int) {
      switch (int) {
        case 1:
          return "Member";
        case 2:
          return "Lead Member";
        case 3:
          return "Manager";
        case 4:
          return "Owner";
        default:
          return "N/A";
      }
    },
    logoUpload(e) {
      this.businessinfo.change_logo = true;
      this.businessinfo.logo = e.target.files[0];
    },
    UpdateBusiness() {
      let id = this.$route.params.id;
      this.store
        .dispatch("Business/UpdateBusinessInfo", {
          id: id,
          address: this.businessinfo.address,
          postcode: this.businessinfo.postcode,
          contact_email: this.businessinfo.contact_email,
          contact_telephone: this.businessinfo.contact_telephone,
          logo: this.businessinfo.logo,
          change_logo: this.businessinfo.change_logo,
          mkit: this.businessinfo.mkit,
        })
        .then((data) => {
          console.log(data);
          this.messages = data.messages;
        })
        .catch((err) => {
          console.log(err);
          this.errors = err.messages;
        });
    },
    RemoveUserFromBusiness(user, index) {
      this.store
        .dispatch("Business/RemoveUserFromBusiness", {
          id: this.$route.params.id,
          user_id: user,
        })
        .then((result) => {
          this.messages = result.messages;
          this.errors = [];
          this.users.splice(index, 1);
        })
        .catch((err) => {
          this.errors = err.messages;
          this.messages = [];
        });
    },
  },
  created() {
    this.GetBusinessInfo();
    this.GetBusinessUsers();
  },
};
</script>

<style>
.avail-test-placeholder {
  align-content: center;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
}
.tests {
  position: absolute;
  background: #ffffff;
  color: #4d4d4d;
  font-family: Arial, Helvetica, sans-serif;
  padding: 10px;
  border: 2px solid #e4e7ee;
  border-radius: 100%;
  font-weight: bolder;
  font-size: 40px;
  display: block;
  z-index: 2;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.title {
  background: rgba(53, 187, 164, 19%);
  color: #35bba4;
  font-family: Arial, Helvetica, sans-serif;
  padding: 20px;
  font-weight: bold;
  border-radius: 10px;
  font-size: 30px;
  display: block;
  margin-top: 85px;
  line-height: 67px;
  height: 40px;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  text-align: center;
}
</style>